export function createFromRange(length: number) {
  return function <T = string | number>(cb: (x: number) => T) {
    return Array(length)
      .fill(null)
      .map((_, index) => cb(index));
  };
}

export function randomColor() {
  const o = Math.round,
    r = Math.random,
    s = 150;
  return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
}

// const fromRange = createFromRange(100);
// console.log(JSON.stringify(fromRange((x) => randomColor())));

export const baseColors = [
  'rgba(79,17,97,0.4)',
  'rgba(35,121,25,0.8)',
  'rgba(41,42,124,0.4)',
  'rgba(128,23,33,0.8)',
  'rgba(30,129,33,0.9)',
  'rgba(114,104,2,0.5)',
  'rgba(78,46,121,0.4)',
  'rgba(42,109,53,0.1)',
  'rgba(97,63,3,0.3)',
  'rgba(144,33,107,0.2)',
  'rgba(139,33,133,1.0)',
  'rgba(138,79,49,0.5)',
  'rgba(17,120,137,0.1)',
  'rgba(111,1,122,0.9)',
  'rgba(76,35,98,0.2)',
  'rgba(27,120,7,0.5)',
  'rgba(93,91,8,1.0)',
  'rgba(44,71,30,0.2)',
  'rgba(77,64,126,1.0)',
  'rgba(61,107,4,0.4)',
  'rgba(48,141,97,0.3)',
  'rgba(52,25,42,0.0)',
  'rgba(136,58,64,0.2)',
  'rgba(86,91,43,0.8)',
  'rgba(134,20,6,0.4)',
  'rgba(118,6,44,0.5)',
  'rgba(27,36,132,0.1)',
  'rgba(37,120,149,0.3)',
  'rgba(143,133,23,0.3)',
  'rgba(11,86,10,0.1)',
  'rgba(119,109,122,0.2)',
  'rgba(88,119,139,0.8)',
  'rgba(76,92,23,0.1)',
  'rgba(37,145,57,0.6)',
  'rgba(91,150,133,0.5)',
  'rgba(71,84,9,0.4)',
  'rgba(27,17,63,0.1)',
  'rgba(110,56,121,0.3)',
  'rgba(49,79,17,0.3)',
  'rgba(14,119,12,0.9)',
  'rgba(79,37,142,0.1)',
  'rgba(12,123,61,0.2)',
  'rgba(54,19,142,0.3)',
  'rgba(142,22,16,0.2)',
  'rgba(137,130,52,0.0)',
  'rgba(35,137,26,0.4)',
  'rgba(96,126,78,0.1)',
  'rgba(108,34,24,0.0)',
  'rgba(97,106,123,0.0)',
  'rgba(69,28,70,0.1)',
  'rgba(143,115,86,0.5)',
  'rgba(14,141,107,0.1)',
  'rgba(97,1,113,0.6)',
  'rgba(134,66,117,0.2)',
  'rgba(84,30,106,0.7)',
  'rgba(23,7,5,0.6)',
  'rgba(147,87,11,0.1)',
  'rgba(80,129,26,0.8)',
  'rgba(92,56,123,0.5)',
  'rgba(24,143,90,0.7)',
  'rgba(116,86,96,0.0)',
  'rgba(116,132,7,0.9)',
  'rgba(95,58,62,0.5)',
  'rgba(73,16,98,1.0)',
  'rgba(117,67,1,0.4)',
  'rgba(87,47,56,0.1)',
  'rgba(120,29,73,0.2)',
  'rgba(42,139,37,0.4)',
  'rgba(116,75,61,0.7)',
  'rgba(14,143,31,0.7)',
  'rgba(33,66,121,0.5)',
  'rgba(97,66,37,0.4)',
  'rgba(105,48,19,0.4)',
  'rgba(144,144,84,0.9)',
  'rgba(140,133,132,0.4)',
  'rgba(91,148,89,0.6)',
  'rgba(32,71,22,0.4)',
  'rgba(31,149,96,0.3)',
  'rgba(40,56,74,0.9)',
  'rgba(86,90,97,0.7)',
  'rgba(145,10,120,0.3)',
  'rgba(104,102,129,0.5)',
  'rgba(68,13,28,0.2)',
  'rgba(27,25,8,0.9)',
  'rgba(50,72,134,0.9)',
  'rgba(15,103,80,0.6)',
  'rgba(31,28,11,0.8)',
  'rgba(115,96,103,0.3)',
  'rgba(34,127,14,0.7)',
  'rgba(34,92,107,0.1)',
  'rgba(44,145,27,0.1)',
  'rgba(123,81,71,0.7)',
  'rgba(18,128,108,0.2)',
  'rgba(7,59,59,0.7)',
  'rgba(82,75,96,0.8)',
  'rgba(66,1,7,0.8)',
  'rgba(132,74,125,0.9)',
  'rgba(126,137,85,0.4)',
  'rgba(82,52,137,0.8)',
  'rgba(125,112,130,0.8)'
];
